<template>
  <div>
    <ul>
      <li v-for="(item, index) in lists" :key="index">
        <div class="flex">
          <div class="item1"></div>
          <div class="item2">
            <div class="item_2_1">{{ item.title }}</div>
            <div class="item_2_2">
              练习总题目数：<span>{{ item.total || 0 }}题</span>
            </div>
            <div class="item_2_3">
              正确率：<span>{{
                item.record.correct > 100 ? 100 : item.record.correct
              }}</span>
            </div>
          </div>
        </div>
        <div class="item3">
          <div class="item3_1">
            练习结果：{{ item.record.result == 1 ? "及格" : "不及格" }}
          </div>
          <div class="item3_2" @click="go(item)">
            {{ item.record.result == 1 ? "已通过" : "再练习" }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["lists"],
  methods: {
    go(item) {
      if (item.record.result == 1) {
        this.$message({
          message: "你已及格无需练习",
          type: "success",
        });
        return;
      }
      this.$router.push({
        path: "/Layoutstudy/writingexercise",
        query: {
          type: 5,
          tabtitle: "章节练习",
          up: "练答",
          ...item,
        },
      });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
ul {
  li {
    display: flex;
    justify-content: space-between;
    height: 150px;
    background: #ffffff;
    padding: 35px 8px;
    box-sizing: border-box;
    border-bottom: 1px solid #e1e1e1;
    .item1 {
      transform: translateY(7px);
      margin-right: 14px;
      width: 6px;
      height: 6px;
      background: #0038ff;
      border-radius: 50%;
    }
    .item2 {
      .item_2_1 {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
      }
      .item_2_2 {
        margin-top: 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7d7d7d;
        span {
          color: #0038ff;
        }
      }
      .item_2_3 {
        margin-top: 10px;

        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7d7d7d;
        span {
          color: #f0733d;
        }
      }
    }
    .item3 {
      text-align: right;
      .item3_1 {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7d7d7d;
        margin-bottom: 30px;
      }
      .item3_2 {
        float: right;
        cursor: pointer;
        text-align: center;
        line-height: 25px;
        width: 100px;
        height: 30px;
        background: #e9f3ff;
        border: 2px solid #137cfb;
      }
    }
  }
}
</style>
