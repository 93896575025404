<template>
  <div>
    <div class="tab">
      <div @click="active = 1" :class="active == 1 ? 'item active' : 'item'">
        章节练习
      </div>
      <div @click="active = 2" :class="active == 2 ? 'item active' : 'item'">
        题库练习
      </div>
      <div @click="active = 3" :class="active == 3 ? 'item active' : 'item'">
        错题练习
      </div>
    </div>
    <writetime type="6"></writetime>
    <chapterpractice :lists="list" v-if="active == 1"> </chapterpractice>
    <div class="li" v-if="active == 2">
      <div>
        <img src="../../assets/lx2.png" alt="" />
        题库练习
      </div>
      <div class="cup" @click="go1">去练习</div>
    </div>
    <div class="li" v-if="active == 3">
      <div>
        <img src="../../assets/lx1.png" alt="" />
        错题练习
      </div>
      <div class="cup" @click="go">去练习</div>
    </div>
    <div style="height: 500px"></div>
  </div>
</template>

<script>
import chapterpractice from "../../components/chapterpractice";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    chapterpractice,
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      classid: (state) => state.classid,
    }),
  },
  data() {
    return {
      active: 1,
      list: [],
    };
  },
  methods: {
    ...mapActions(["getChapterCatalog", "getchapterList_x"]),
    // 跳转题库练习
    go1(item) {
      this.$router.push({
        path: "/Layoutstudy/writingexercise",
        query: {
          type: 6,
          tabtitle: "题库练习",
          up: "练答",
        },
      });
    },
    // 跳转错题练习
    go() {
      this.$router.push({
        path: "/Layoutstudy/writingexercise",
        query: {
          type: 2,
          tabtitle: "错题练习",
          up: "练答",
        },
      });
    },
    // 课程信息
    tabClick(item) {
      this.$store.commit("SET_TABINDEX", item);
      if (item == 1) {
        this.getchapterList_x({
          class_id: this.classid, //班级id
          student_id: this.userInfo.id, //学员id
        }).then((res) => {
          if (res.successCode == 200) {
            res.responseBody = res.responseBody.map((e) => {
              e.type = "5"; //章节练习标记
              return e;
            });
            this.list = res.responseBody;
          } else {
            this.$message({
              message: res.message,
              type: "warning",
            });
          }
        });
      }
    },
  },
  mounted() {
    this.tabClick(this.active);
  },
};
</script>

<style lang="scss" scoped>
.li {
  margin-top: 10px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  height: 100px;
  font-size: 16px;
  line-height: 100px;
  box-shadow: #ccc 0px 0px 10px;
  img {
    vertical-align: middle;
  }
  .cup {
    cursor: pointer;
  }
}
.tab {
  display: flex;
  height: 77px;
  line-height: 77px;
  justify-content: space-around;
  border-bottom: 1px solid #e1e1e1;
  .item {
    cursor: pointer;
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #a7a7a7;
  }
  .active {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    &::before {
      position: absolute;
      display: inline-block;
      content: "";
      width: 56px;
      height: 4px;
      background: #137cfb;
      bottom: -3px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
