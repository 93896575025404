import { render, staticRenderFns } from "./chapterpractice.vue?vue&type=template&id=77cb01ee&scoped=true&"
import script from "./chapterpractice.vue?vue&type=script&lang=js&"
export * from "./chapterpractice.vue?vue&type=script&lang=js&"
import style0 from "./chapterpractice.vue?vue&type=style&index=0&id=77cb01ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77cb01ee",
  null
  
)

export default component.exports